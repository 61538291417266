<template>
  <div class="home">
    <div class="header">
      <span>签约信息</span>
    </div>
    <!-- <div class="nav">开户信息</div>
    <ul class="content">
      <li>
        <span>开户名称</span>
        <div>{{enterpriseInfo.payeeName}}</div>
      </li>
      <li>
        <span>对公账号</span>
        <div>{{enterpriseInfo.payeeBankNo}}</div>
      </li>
      <li style="width:100%">
        <span>开户银行</span>
        <div>{{enterpriseInfo.bankName}}</div>
      </li>
    </ul> -->
    <div class="nav">合同信息</div>
    <ul class="content">
      <li>
        <span>合同编号</span>
        <div>{{htInfo.contractNo}}</div>
      </li>
      <li>
        <span>合同名称</span>
        <div>{{htInfo.contractName}}</div>
      </li>
      <li style="width:100%">
        <span>合同有效期</span>
        <div>{{htInfo.contractValidity}}</div>
      </li>
    </ul>
    <div class="nav">限额信息</div>
    <ul class="content">
      <li style="width:100%">
        <span>打款通道</span>
        <div>单笔订单额度上限(元)：{{xeInfo.bankQuota}}</div>
      </li>
    </ul>
    <div class="nav">计费信息</div>
    <div>
      <a-table :rowKey="(record) => record.id" :columns="columns" :data-source="dataLists" bordered :pagination="pagination">
        <span slot="name" slot-scope="text">{{ text }}</span>
        <span slot="customTitle">结束费用(元) <a-tooltip placement="top" title="包含结束费用"><a-icon type="exclamation-circle" theme="filled" style="color:#40a9ff" /></a-tooltip></span>
      </a-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ACCESS_TOKEN,USER_INFO } from "@/store/mutation-types";
import {
  getEnterpriseBankInfo,
  getQuotas,
  getServiceRule,
  getContractInfo,
} from "@/api/api";
export default {
  name: 'Home',
  created () {
    this.getEnterpriseBankInfo();
    this.getServiceRule();
    this.getContractInfo();
    this.getQuotas();
  },
  data () {
    return {
      enterpriseInfo: '',
      htInfo: '',
      flInfo: '',
      xeInfo: '',
      columns : [
        {
          title: '起始费用(元)',
          dataIndex: 'ruleBegin',
          customRender: text => text.toFixed(2)
        },
        {
          dataIndex: 'ruleEnd',
          key: 'name',
          slots: { title: 'customTitle' },
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '费用利率(%)',
          dataIndex: 'feePoint',
          customRender: text => (text*100).toFixed(2)
        }
      ],
      dataLists: [],
      pagination: {
        hideOnSinglePage: true
      }
    }
  },
  methods: {
    // 获取费率
    getServiceRule () {
      getServiceRule().then(res => {
        if (res.success) {
          this.flInfo = res.result
          this.dataLists = res.result===null?'':res.result
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取合同信息
    getContractInfo () {
      getContractInfo().then(res => {
        if (res.success) {
          this.htInfo = res.result
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取限额
    getQuotas () {
      getQuotas().then(res => {
        if (res.success) {
          this.xeInfo = res.result
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取结算信息
    getEnterpriseBankInfo () {
      getEnterpriseBankInfo().then(res => {
        if (res.success) {
          this.enterpriseInfo = res.result
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    color: #283039;
    font-size: 18px;
    font-weight: bold;
  }
}
.nav{
  color: #283039;
  font-size: 14px;
  margin: 30px 0 18px 0;
}
.content{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  li{
    display: flex;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    font-size: 12px;
    color: #727682;
    span{
      width: 140px;
      height: 54px;
      background: #FAFAFA;
      padding: 20px 18px;
    }
    div{
      padding: 20px 18px;
      height: 54px;
    }
  }
}
</style>
